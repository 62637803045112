/* This example requires Tailwind CSS v2.0+ */
import {
  DialogBackdrop,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Megamenu from './Megamenu';
import { Fragment, useState } from 'react';
import { Dialog } from '@headlessui/react';
import MenuMobile from './MenuMobile';
import localizedRoute from '../../utils/localizedRoute';
import MenuActions from './MenuActions';
import logo from '../../public/img/logo.svg';
import { useTranslation } from 'react-i18next';
import BannerShipping from '../BannerShipping';
import Image from 'next/image';

export default function SecondMenu() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white">
        {/* Mobile menu */}
        <Transition show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-y-0 right-0 flex z-40 lg:hidden max-w-full"
            onClose={setOpen}
          >
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-xs z-10">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-lg font-medium text-gray-900"></DialogTitle>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="bg-white rounded-md hover:text-gray-500 focus:outline-none"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">{t('CANCEL')}</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    <MenuMobile close={() => setOpen(false)} />
                  </div>
                </div>
              </div>
            </TransitionChild>
          </Dialog>
        </Transition>

        <header className="relative bg-white">
          <nav
            aria-label="Top"
            className="px-4 sm:px-6 lg:px-12 border-b border-gray-200"
          >
            <div>
              <div
                id="header__container"
                className="h-16 flex items-center justify-between"
              >
                <div className="lg:flex-1 lg:flex lg:items-center">
                  <a href={localizedRoute('/')}>
                    <Image
                      src={logo}
                      className="w-40 cursor-pointer"
                      alt="logo"
                    />
                  </a>
                </div>

                <Megamenu />

                <div
                  id="header__container-actions"
                  className="flex-1 flex items-center justify-end"
                >
                  <MenuActions />
                </div>
                <button
                  type="button"
                  className="bg-white p-2 pr-0 rounded-md lg:hidden ml-3"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">{t('OPEN_MENU')}</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <BannerShipping />
    </>
  );
}
