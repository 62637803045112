import React from 'react';
import { FooterSection } from '../models/footer';
import localizedRoute from '../utils/localizedRoute';
import { useTranslation } from 'react-i18next';
import ChangeLangCountry from './ChangeLangCountry';
import { getLangCountry } from '../helpers/languages';
import { useRouter } from 'next/router';
import { fetchFooter } from '../fetchers/footer';
import logoWhite from '../public/img/logo-white.svg';
import FooterLinks from './FooterLinks';
import parse from 'html-react-parser';
import Image from 'next/image';

const Footer = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  const footer = fetchFooter({ lang });

  return (
    <footer className="bg-primary" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        {t('FOOTER_HEAD')}
      </h2>
      <div className="mx-auto py-8 px-4 sm:px-6 lg:px-12">
        <div className="md:flex md:items-center md:justify-between pb-8">
          <div className="mt-6">
            <Image
              src={logoWhite}
              className="w-40 filter brightness-0 invert"
              alt="logo"
            />
          </div>
        </div>
        <div className="pt-8 pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4 md:grid-cols-4 xl:grid-cols-4 ">
            {footer.map((section: FooterSection) => {
              return (
                <div key={`key-${section.title}`} className="mt-12 md:mt-0">
                  <h3 className="text-sm font-normal text-white tracking-wider">
                    {section.title}
                  </h3>
                  <ul className="mt-2 space-y-1">
                    {section.items.map(item => {
                      const { link = '', title = '' } = item;
                      return (
                        <li key={title}>
                          <a
                            href={
                              link.startsWith('http')
                                ? link
                                : localizedRoute(link)
                            }
                            className="text-sm text-white opacity-40 hover:text-white font-normal"
                          >
                            {parse(title)}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="mt-12 xl:mt-0">
            <h3 className="text-sm mb-2 font-normal text-white tracking-wider">
              {t('COUNTRY_SHIPPING')}
            </h3>
            <ChangeLangCountry />
          </div>
        </div>

        <FooterLinks lang={lang} country={country} />
      </div>
    </footer>
  );
};

export default Footer;
