import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchColors } from '../../fetchers/mapping';
import { fetchShippingCountriesList } from '../../fetchers/shipping';
import { getLangCountry } from '../../helpers/languages';
import { getCurrencyName } from '../../helpers/prices';
import usePrice from '../../hooks/usePrice';
import { ICartPrice, ICartProduct } from '../../models/cart';
import localizedRoute from '../../utils/localizedRoute';
import LinkTo from '../LinkTo';
import MiniSpinner from '../MiniSpinner';

const CartProduct = ({
  product,
  onChangeQty,
  onRemoveProduct
}: {
  product: ICartProduct;
  onChangeQty: (product: ICartProduct, qty: number) => Promise<void>;
  onRemoveProduct: (product: ICartProduct) => Promise<void>;
}) => {
  const [qty, setQty] = useState(product.quantity);
  const [formatPrice] = usePrice();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const colorsCodeToLabel = fetchColors();
  const { lang, country } = getLangCountry(router.query);
  const countries = fetchShippingCountriesList({ lang });
  const currencyCode = getCurrencyName(countries, country);

  const getColorLabel = (color_code: string) => {
    const { color = '' } = colorsCodeToLabel[color_code] || {};
    return color;
  };

  useEffect(() => {
    updateProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qty]);

  const updateProduct = async () => {
    if (product.quantity === qty) {
      return;
    }
    setLoading(true);
    await onChangeQty(product, qty);
    setLoading(false);
  };

  const removeProduct = async () => {
    setLoading(true);
    await onRemoveProduct(product);
    setLoading(false);

    window.dataLayer?.push({
      event: 'removeFromCart',
      ecommerce: {
        currencyCode,
        remove: {
          products: [
            {
              id: product['product-sku'],
              name: product.name,
              price: (product.price as ICartPrice)?.price,
              brand: 'Prima Brands',
              category: '',
              quantity: product.quantity
            }
          ]
        }
      }
    });
  };

  const ORIGINAL_PRICE =
    typeof product.price !== 'object'
      ? product.price
      : product.price?.price || 0;

  const SALE_PRICE =
    typeof product.price !== 'object' ? 0 : product.price?.sale_price || 0;

  return (
    <>
      <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
        <img
          src={product.image}
          alt={product.name}
          className="w-full h-full object-center object-cover"
        />
      </div>

      <div className="ml-4 flex-1 flex flex-col">
        <div>
          <div className="flex justify-between text-sm font-medium text-gray-900">
            <h3>
              <LinkTo href={localizedRoute(product.slug)}>
                {product.name}
              </LinkTo>
            </h3>
            <p className="ml-4">
              {SALE_PRICE ? (
                <>
                  <span className="line-through	text-gray-400">
                    {formatPrice(ORIGINAL_PRICE)}
                  </span>
                  <span className="font-bold"> {formatPrice(SALE_PRICE)}</span>
                </>
              ) : (
                formatPrice(ORIGINAL_PRICE)
              )}
            </p>
          </div>
          <p className="mt-1 text-xs text-gray-500">
            {product.size} | {getColorLabel(product.color)}
          </p>
        </div>
        <div className="flex-1 flex items-end justify-between text-sm">
          <label
            htmlFor={`quantity-${product['product-sku']}`}
            className="sr-only"
          >
            {t('QUANTITY')}, {product.name}
          </label>
          <select
            id={`quantity-${product['product-sku']}`}
            name={`quantity-${product['product-sku']}`}
            value={qty}
            onChange={e => {
              setQty(Number(e.target.value));
            }}
            onBlur={e => {}}
            className="bg-gray-100 block max-w-full rounded-md border-none py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>

          <div className="flex">
            <button
              type="button"
              className="font-medium text-primary inline-flex items-center mb-1"
              onClick={removeProduct}
            >
              {loading && <MiniSpinner />}
              {t('REMOVE')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartProduct;
