import React from 'react';

const MenuBadge = ({ text }: { text: string }) => {
  const isTop = text.toLowerCase() === 'top';

  return (
    <div className="bg-white py-1 px-2 ml-2 rounded-md">
      <span className="text-12 font-medium text-primary">{text}</span>
    </div>
  );
};

export default MenuBadge;
