import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fetchShippingCost,
  fetchShippingCountriesList
} from '../fetchers/shipping';
import { useRouter } from 'next/router';
import { getLangCountry } from '../helpers/languages';
import { getCurrencySymbol } from '../helpers/prices';

const ICON_SHIPPING = '/img/icon-shipping.svg';
const ICON_RETURN = '/img/icon-return.svg';
const ICON_CREDITCARD = '/img/icon-creditCard.svg';

const BannerShipping = () => {
  const { t } = useTranslation();

  const [free, setFree] = useState(-1);
  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);
  const countries = fetchShippingCountriesList({ lang });
  const currencySymbol = getCurrencySymbol(countries, country);

  useEffect(() => {
    if (country) {
      setFreeShippingCost();
    }
  }, [country]);

  const setFreeShippingCost = async () => {
    const free = await fetchShippingCost(country);
    setFree(free);
  };

  const cyber_monday_banner_message = t(
    'CYBER_MONDAY_2022_TOPBAR_MESSAGE'
  ).replace('%s', currencySymbol);

  return (
    <div className="relative justify-center items-center py-2 text-xs sm:text-sm pl-4 pr-10 sm:pl-0 sm:pr-0 text-white bg-primary hidden md:flex">
      <div className="flex">
        {free > 0 && (
          <div className="flex mx-1 sm:mx-2 items-center flex-row text-white">
            <img
              alt="filter"
              src={ICON_SHIPPING}
              className="w-4 h-4 inline-block mr-2"
              aria-hidden="true"
            />
            {t('TOPBAR_SHIPPING', { currencySymbol, free })}
          </div>
        )}
        <div className="flex mx-1 sm:mx-2 items-center flex-row">
          <img
            alt="filter"
            src={ICON_RETURN}
            className="w-4 h-4 inline-block mr-2"
            aria-hidden="true"
          />
          {t('TOPBAR_RETURN')}
        </div>
        <div className="flex mx-1 sm:mx-2 items-center flex-row font-normal">
          <img
            alt="filter"
            src={ICON_CREDITCARD}
            className="w-4 h-4 inline-block mr-2 "
            aria-hidden="true"
          />
          {t('TOPBAR_PAYMENTS')}
        </div>
      </div>
    </div>

    // PROMO TOPBAR
    // <div className="relative justify-center items-center py-3 text-sm lg:text-base pl-4 pr-10 sm:pl-0 sm:pr-0 text-white bg-black flex">
    //   <div className="flex">
    //     <div className="flex mx-1 sm:mx-2  items-center flex-row text-white animate-pulse">
    //       <p className="">{cyber_monday_banner_message}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BannerShipping;
