import React from 'react';

const SecondaryButtonBig = ({
  text,
  onClick,
  className = '',
  ...props
}: {
  text: any;
  className?: string;
  onClick?: any;
}) => {
  return (
    <div
      className={
        `text-center bg-primary text-white px-3 py-4 rounded-md border border-primary ` +
        className
      }
      onClick={onClick}
      {...props}
    >
      <span>{text}</span>
    </div>
  );
};

export default SecondaryButtonBig;
