import { useCookies } from 'react-cookie';

export const isLogged = () => {
  const [cookies] = useCookies(['prima_authenticated']);
  return cookies['prima_authenticated'] === 'true';
};

export const getToken = () => {
  const [cookies] = useCookies(['prima_bearer']);
  return cookies['prima_bearer'];
};
