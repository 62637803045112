import Link, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

export default function LinkTo({
  children,
  ...props
}: LinkProps & React.HTMLProps<HTMLAnchorElement>) {
  return (
    <Link prefetch={false} {...props}>
      {React.Children.map<ReactNode, ReactNode>(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child);
        }
        return child;
      })}
    </Link>
  );
}
