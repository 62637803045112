import React from 'react';
import { classNames } from '../helpers/classes';

interface ButtonProps {
  type?: 'submit' | 'button' | 'reset' | undefined;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  ref?: any;
  className?: string;
}

const BlueButtonBig: React.FC<ButtonProps> = ({
  type = 'submit',
  text,
  onClick,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        'bg-white disabled:opacity-50 w-full text-center text-black font-bold px-3 py-4 rounded-md cursor-pointer',
        className
      )}
      {...props}
    >
      <span>{text}</span>
    </button>
  );
};

export default BlueButtonBig;
